<template>
  <div>
    <h1>{{ bookData.title }}</h1>
    <h3 v-if="bookData.authors && bookData.authors.length">
      {{ $t("page.book.by") }}
      <span v-for="(author, index) in bookData.authors" :key="author.name"
        >{{ author.name }}
        <span v-if="index != bookData.authors.length - 1">, </span>
      </span>
    </h3>
  </div>
</template>
<script>
export default {
  props: ["book"],
  computed: {
    bookData() {
      return this.book;
    },
  },
  watch: {},
};
</script>