<template>
  <div v-if="authorList.length">
    {{ $tc("page.book.author", authorList.length) }}
    <v-card outlined v-for="author in authorList" :key="author.key">
      <v-card-title
        ><a :to="{ name: toAuthor, params: { authorId: author.id } }">{{
          author.name
        }}</a></v-card-title
      >
      <v-card-text>{{ author.bio }}</v-card-text>
    </v-card>
  </div>
</template>
<script>
import * as Routes from "#Routes";
export default {
  props: ["authors"],
  computed: {
    authorList() {
      return this.authors == null || !this.authors.length ? [] : this.authors;
    },
    toAuthor() {
      return Routes.AUTHOR;
    },
  },
};
</script>