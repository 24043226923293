<template>
  <v-row justify-md="center">
    <v-col md="8" v-if="Object.keys(book).length">
      <v-row>
        <v-col md="8">
          <v-col md="12"><book-title :book="book"></book-title></v-col>
          <v-col md="12">
            <v-btn
              raised
              class="primary mx-1"
              :disabled="true"
              @click="read()"
              >{{ $t("page.book.actions.read") }}</v-btn
            >
            <v-btn class="primary ml-1" dark @click="download('PDF')">
              {{ $t("page.book.actions.downloadPdf") }}
            </v-btn>
            <v-menu offset-y v-if="hasExtraExtensions">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="primary mr-1" dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in filteredExtensions"
                  :key="index"
                >
                  <v-list-item-title
                    class="pointer"
                    @click="download(item.type)"
                    >{{ $t("page.book.actions.download") }}
                    {{ item.type }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y v-if="canUpdate">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="primary mx-1" dark v-bind="attrs" v-on="on">
                  {{ $t("page.book.actions.more") }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title class="pointer" @click="editBook()">{{
                    $t("page.book.actions.edit")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="book.isActive">
                  <v-list-item-title @click="deactivateBook()"
                    >{{ $t("page.book.actions.setInactive") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-else>
                  <v-list-item-title @click="activateBook()">{{
                    $t("page.book.displayBook")
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-col>
        <v-col md="4">
          <book-cover
            :coverImage="book.coverImage"
            :isActive="book.isActive"
            :size="225"
          ></book-cover>
          <!-- <v-img
            class="mt-2"
            :src="coverImage"
            lazy-src="/img/book-not-found.jpg"
            max-height="200px"
            contain
            :gradient="
              !book.isActive
                ? 'to bottom right, rgba(255,255,255,1), rgba(0,0,0,.5)'
                : ''
            "
          ></v-img> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8">
          <v-card outlined>
            <v-card-title>{{ $t("page.book.about") }}</v-card-title>
            <v-card-text>
              <p class="body-1" v-if="book.exerpt">
                {{ book.exerpt }}
              </p>
              <p v-else>
                {{ $t("page.book.noExerpt") }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="4">
          <v-card outlined class="mb-4">
            <v-card-title>{{ $t("page.book.meta") }}</v-card-title>
            <v-card-text>
              <p>
                {{ $t("page.book.addedBy") }}:
                <a @click="navigateTo(book.addedById)">{{ book.addedBy }}</a>
              </p>
              <p>
                {{ $t("page.book.publishingYear") }}:
                <span v-if="book.publishingYear">{{
                  $moment(book.publishingYear, "YYYY MM")
                }}</span>
                <span v-else>?</span>
              </p>
              <p>
                {{ $t("page.book.ISBN") }}:
                <span v-if="book.ISBN">{{ book.isbn }}</span>
                <span v-else>?</span>
              </p>
              <p v-if="isInRole(['Sudo', 'Moderator'])">
                {{ $t("page.book.timeOpened") }}:
                {{ book.timeOpened }}
              </p>
              <p v-if="isInRole(['Sudo', 'Moderator'])">
                {{ $t("page.book.timeDownloaded") }}:
                {{ book.timeDownloaded }}
              </p>
            </v-card-text>
          </v-card>
          <book-author :authors="book.authors"></book-author>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import BookTitle from "../../components/Book/BookTitle";
import BookAuthor from "../../components/Book/BookAuthor";
import BookCover from "../../components/Book/BookCover";
import * as Routes from "#Routes";
import roleMethods from "../../mixins/role";
export default {
  props: ["bookId"],
  components: { BookTitle, BookAuthor, BookCover },
  mixins: [roleMethods],
  data() {
    return {
      book: {},
      actions: [
        {
          title: this.$t("page.book.actions.edit"),
          onClick: () => this.log("edit"),
        },
        {
          title: this.$t("page.book.actions.setInactive"),
          onClick: () => this.deactivateBook(),
        },
        {
          title: this.$t("page.book.displayBook"),
          onClick: () => this.activateBook(),
        },
      ],
    };
  },
  computed: {
    coverImage() {
      if (this.book == null || this.book.coverImage == null) {
        return "/img/book-not-found.jpg";
      } else {
        return this.book.coverImage;
      }
    },
    canUpdate() {
      var userId = this.$store.getters["auth/publicId"];
      return (
        this.$store.getters["auth/role"] != "Member" ||
        userId == this.book.addedById
      );
    },
    toUserProfile() {
      return Routes.USER_PROFILE;
    },
    filteredExtensions() {
      if (this.book != null)
        return this.book.extensions.filter(
          (x) => x.type.toLowerCase() != "pdf"
        );
      return null;
    },
    hasExtraExtensions() {
      var ext = this.filteredExtensions;
      return ext != null && ext.length > 0;
    },
  },
  watch: {},
  methods: {
    read() {
      alert("Read");
      //   this.$store.dispatch("notification/addModal", {
      //     componentName: "book-form",
      //     fullscreen: true,
      //   });
    },
    execute(fn) {
      fn();
    },
    setBook(book) {
      this.book = book;
    },
    deactivateBook() {
      this.$store
        .dispatch("library/deactivateBook", this.book.publicId)
        .then((inactive) => {
          this.book.isActive = !inactive;
        });
    },
    activateBook() {
      this.$store
        .dispatch("library/updateBook", {
          bookId: this.book.id,
          isActive: true,
        })
        .then((res) => {
          this.book.isActive == res.isActive;
        });
    },
    editBook() {
      this.$store.dispatch("notification/showBookForm", {
        parameter: { book: this.book },
      });
      //   this.$store.dispatch("notification/addModal", {
      //     componentName: "book-form",
      //     parameter: { book: this.book },
      //   });
    },
    navigateTo(userId) {
      this.$router.push({
        name: this.toUserProfile,
        params: { userId: userId },
      });
    },
    download(extention) {
      this.$store.dispatch("library/downloadBook", {
        bookId: this.book.publicId,
        bookName: this.book.title,
        format: extention,
      });
      //alert(extention);
    },
  },
  created() {
    this.$store.dispatch("library/fetchBookData", this.bookId).then((res) => {
      this.book = res;
    });
  },
};
</script>